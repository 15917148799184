import { ref } from "vue";
import { useRenderRichText } from "./RenderRichText";

export function useRichText(blok, level = 1, titleSize = "size-m") {
  let title = ref("");
  let subtitle = ref("");
  let text = ref("");

  if (typeof blok.title?.content !== "undefined") {
    title.value = blok.title?.content[0]?.content
      ? useRenderRichText(blok.title)
          .replace(/^<p/, `<h${level} class="title ${titleSize}"`)
          .replace(/\/p>$/, `/h${level}>`)
      : "";
  }

  if (typeof blok.subtitle?.content !== "undefined") {
    subtitle.value = blok.subtitle?.content[0]?.content
      ? useRenderRichText(blok.subtitle)
          .replace(/^<p/, `<h${level + 1} class="subtitle"`)
          .replace(/\/p>$/, `/h${level + 1}>`)
      : "";
  }

  if (typeof blok.text?.content !== "undefined") {
    text.value = blok.text?.content[0]?.content
      ? useRenderRichText(blok.text)
      : "";
  }

  return {
    title,
    subtitle,
    text,
  };
}

export function getTextFromRichText(object) {
  return object.content[0]?.content[0]?.text;
}